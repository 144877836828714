<template>
  <div class="pdf-content">
    <v-col align="center">
      <v-sheet elevation="0" width="900">
        <v-row align="center">
          <v-img
            contain
            max-width="150"
            :src="require('../assets/logo-letras_negras.png')"
          ></v-img>
          <v-col cols="12" md="6" align="center">
            <h3>Declaracion Diaria C19 Aríztia</h3>
          </v-col>
          <v-img contain max-width="100" :src="status_img"></v-img>
          <v-col cols="12" align="left">
            Mediante la presente {{ nombre }}, Rut: {{ rut }} declara lo
            siguiente:
            <br />
            <br /><v-icon>mdi-chevron-right</v-icon> {{ pregunta1 }} tenido
            contacto con algun paciente diagnosticado y en cuarentena por
            coronavirus (COVID19) <br /><v-icon>mdi-chevron-right</v-icon>
            {{ pregunta2 }} presentado sintomas que puedan asociarse a
            enfermedad por coronavirus (COVID19) <br /><v-icon
              >mdi-chevron-right</v-icon
            >
            Dentro de su circulo familiar directo {{ pregunta3 }} personas a la
            espera de resultados PCR por sospecha de coronavirus (COVID19)
            <br />
            <!-- rehabilitar esto cuando se autorice la restriccion de acceso -->
            <!-- <br>Por consiguiente, y según los datos suministrados por el colaborador,{{confirmacion1}} se autoriza el ingreso a planta{{confirmacion2}}. -->
            <br />
            <br />
            La presente declaración será válida hasta {{ dia_hora }}.
            <br />
            <br /><v-divider></v-divider>
            <br />
            <br />Si se requiere validar esta declaracion, escanear el siguiente
            codigoQR o ingresar a {{ qode }} <br /><v-qrcode
              errorCorrectionLevel="H"
              :value="qode"
            />
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </div>
</template>

<script>
import C19DataService from "../services/C19DataService";
export default {
  name: "certificado",
  data() {
    return {
      rut: "",
      nombre: "",
      pregunta1: "",
      pregunta2: "",
      pregunta3: "",
      confirmacion1: "",
      confirmacion2: "",
      qode: "__",
      dia_hora: "",
      dia_hora_: "",
      status_img: require("../assets/check.png"),
    };
  },
  methods: {
    getRespuesta(id) {
      C19DataService.getRespuesta(id).then((response) => {
        this.rut = response.data[0].rut;
        this.nombre = response.data[0].nombre;
        this.qode = `https://c19ariztia.apidev.info/#/viewpdf/${response.data[0].filename}`;
        if (response.data[0].pregunta1) {
          this.pregunta1 = "Ha";
        } else {
          this.pregunta1 = "No ha";
        }
        if (response.data[0].pregunta2) {
          this.pregunta2 = "Ha";
        } else {
          this.pregunta2 = "No ha";
        }
        if (response.data[0].pregunta3) {
          this.pregunta3 = "tiene";
        } else {
          this.pregunta3 = "no tiene";
        }
        if (!response.data[0].confirmacion) {
          this.status_img = require("../assets/alert.png");
          this.confirmacion1 = " no";
          this.confirmacion2 =
            " hasta que el colaborador verifique su estado de salud en el recinto de salud que le corresponda";
        }
        const fechahor = response.data[0].filename.split("_");
        const validez = new Date(fechahor[1] * 1 + 43200000);
        const validez_ = new Date(fechahor[1] * 1);
        this.dia_hora = `${validez.getDate()}-${new String(
          validez.getMonth() + 1
        ).padStart(
          2,
          "00"
        )}-${validez.getFullYear()} a las ${validez.getHours()}:${new String(
          validez.getMinutes()
        ).padStart(2, "00")}`;
        this.dia_hora_ = `${validez_.getDate()}-${new String(
          validez_.getMonth() + 1
        ).padStart(
          2,
          "00"
        )}-${validez_.getFullYear()} a las ${validez_.getHours()}:${new String(
          validez_.getMinutes()
        ).padStart(2, "00")}`;
      });
    },
  },
  mounted() {
    this.getRespuesta(this.$route.params.id);
  },
};
</script>
